document.addEventListener('DOMContentLoaded', function() {
  
	// Event listener for product/company links
	document.querySelectorAll('tr td a.buy_enquire_link, tr td a.company_link').forEach(function(link) {
	  link.addEventListener('click', function() {
		let product_name = this.closest('tr').dataset.product;
		let company_name = this.closest('tr').dataset.company;
		ga('send', {
		  'hitType': 'event',
		  'eventCategory': 'Product Link Click',
		  'eventAction': company_name,
		  'eventLabel': product_name,
		  'eventValue': 1
		});
	  });
	});
  
	// Event listener for enquiry form submit button
  document.addEventListener('DOMContentLoaded', function() {
    let submitButton = document.querySelector('button.enquiry_form_submit');
    
    if (submitButton) {
      submitButton.addEventListener('click', function() {
        let product_name = document.querySelector('.modal-content #enquiry_product_name')?.value || '';
        let company_name = document.querySelector('.modal-content #enquiry_product_company_name')?.value || '';
        
        ga('send', {
          'hitType': 'event',
          'eventCategory': 'Product Enquiry Made',
          'eventAction': company_name,
          'eventLabel': product_name,
          'eventValue': 1
        });
      });
    }
  });
  
	// Event listener for email enquiry link
	document.querySelectorAll('a.email_enquiry').forEach(function(link) {
	  link.addEventListener('click', function() {
		let product_name = this.closest('tr').dataset.product;
		let company_name = this.closest('tr').dataset.company;
		ga('send', {
		  'hitType': 'event',
		  'eventCategory': 'Product Enquiry Clicked',
		  'eventAction': company_name,
		  'eventLabel': product_name,
		  'eventValue': 1
		});
	  });
	});
  
	// Event listener for view all products link
	document.querySelectorAll('a.view_all_products').forEach(function(link) {
	  link.addEventListener('click', function() {
		let product_name = this.closest('tr').dataset.product;
		let company_name = this.closest('tr').dataset.company;
		ga('send', {
		  'hitType': 'event',
		  'eventCategory': 'View Suppliers Products Clicked',
		  'eventAction': company_name,
		  'eventLabel': product_name,
		  'eventValue': 1
		});
	  });
	});



  // Event listener for the link that opens the modal
  const emailEnquiryLinks = document.querySelectorAll('.email_enquiry');

  // Proceed if there are any email enquiry links on the page
  if (emailEnquiryLinks.length > 0) {
    emailEnquiryLinks.forEach(function(link) {
      link.addEventListener('click', openModal);
    });
  }

  function openModal(event) {
    event.preventDefault();
    const button = event.currentTarget;
    const product_id = button.dataset.product;
    const company = button.dataset.company;

    // Get the modal element
    const enquiryModal = document.getElementById('enquiry_modal');

    // Assume the modal element exists
    // Show the modal
    enquiryModal.style.display = 'block';

    // Fetch and insert the enquiry form
    fetch(`/products/enquiry_form/${product_id}`)
      .then(response => response.text())
      .then(data => {
        enquiryModal.querySelector('.modal-title').textContent = 'New enquiry to ' + company;
        enquiryModal.querySelector('.modal-data').innerHTML = data;

        // After loading the modal content, attach event listeners
        const closeModal = enquiryModal.querySelector('.custom-modal-close');
        const submitButton = enquiryModal.querySelector('.enquiry_form_submit');

        // Attach event listeners assuming elements exist
        closeModal.addEventListener('click', closeModalFunc);
        submitButton.addEventListener('click', submitEnquiryForm);
      })
      .catch(error => {
        console.error('Error fetching the enquiry form:', error);
      });
  }

  // Function to close the modal
  function closeModalFunc() {
    const enquiryModal = document.getElementById('enquiry_modal');
    enquiryModal.style.display = 'none';
  }

  // Handle form submission in the modal
  function submitEnquiryForm() {
    const enquiryModal = document.getElementById('enquiry_modal');
    const enquiryForm = enquiryModal.querySelector('.enquiry_form');
    enquiryForm.submit();
  }

  // Close the modal when clicking outside the modal content
  window.addEventListener('click', function(event) {
    const enquiryModal = document.getElementById('enquiry_modal');
    if (event.target == enquiryModal) {
      closeModalFunc();
    }
  });
});

document.addEventListener('DOMContentLoaded', function() {
  var submitButton = document.querySelector('.enquiry_form_submit');
  var form = document.querySelector('.enquiry_form');
  
  if (submitButton && form) {
    submitButton.addEventListener('click', function(event) {
      event.preventDefault();
      form.submit();
    });
  }
});